import { Routes, Route } from "react-router-dom";

import HomePage from "./pages/HomePage/HomePage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import SignupPage from "./pages/SignupPage/SignupPage";
import LoginPage from "./pages/LoginPage/LoginPage";

import Navbar from "./components/Navbar/Navbar";
import IsPrivate from "./components/IsPrivate/IsPrivate";
import IsAnon from "./components/IsAnon/IsAnon";
import AddToCart from "./pages/AddToCartPage/AddToCart";
import StripeCheckout from "./pages/StripeCheckoutPage/StripeCheckoutPage";
import UploadUserPictures from "./pages/UploadUserPicturesPage/UploadUserPicturesPage";
import UserAvatars from "./pages/UserAvatarsPage/UserAvatarsPage";
import UserProfilePage from "./pages/ProfilePage/UserProfilePage";
import IsPaidUser from "./components/IsPrivate/isPaidUser";

function App() {
  return (
    <div className="bg-gray-900 h-[100vh] text-white">
      <Navbar />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/signup"
          element={
            <IsAnon>
              <SignupPage />
            </IsAnon>
          }
        />
        <Route
          path="/login"
          element={
            <IsAnon>
              <LoginPage />
            </IsAnon>
          }
        />
        <Route
          path="/profile"
          element={
            <IsPrivate>
              <ProfilePage />
            </IsPrivate>
          }
        />
        <Route path="/users/:username" element={
          <IsPrivate>
            <UserProfilePage />
          </IsPrivate>
        } />
        <Route path="/add" element={
          <IsPrivate>
            <AddToCart />
          </IsPrivate>
        } />
        <Route path="/checkout" element={
          <IsPrivate>
            <StripeCheckout />
          </IsPrivate>
        } />
        <Route path="/upload/:username" element={
          <IsPrivate>
            <IsPaidUser>
              <UploadUserPictures />
            </IsPaidUser>
          </IsPrivate>
        } />
        <Route path="/create-avatars/:username" element={
          <IsPrivate>
            <IsPaidUser>
              <UserAvatars />
            </IsPaidUser>
          </IsPrivate>
        } />
      </Routes>
    </div>
  );
}

export default App;
