import React from 'react'
import { useContext } from 'react';
import { AuthContext } from "../../context/auth.context";
import CheckoutButton from '../../components/Payment/CheckoutButton';

const BACKEND_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:5005"

function AddToCart() {
    const { user } = useContext(AuthContext);

    return (
        <div className="flex flex-col pt-12 items-center">
            <div className="bg-white py-8 rounded-md h-full w-[600px] text-black flex flex-col justify-center gap-12 items-center">
                <div className="flex flex-col mx-12 gap-4">
                    <div className="">
                        <span className='text-lg font-bold'>
                            Select a package
                        </span>
                        <br />
                        <span className='text-md text-gray-600'>
                            One-time payment. No subscription. All packages come with a default amount of photos. After generation, you can choose additional styles if you'd like.
                        </span>
                    </div>
                    <div className='flex flex-row w-full items-center justify-between'>
                        <div className="flex flex-col w-72 text-sm text-gray-600">
                            <div className='flex flex-row gap-2'>
                                <span className='font-extrabold text-black'>
                                    4K
                                </span>
                                <span className="text-xs inline-flex items-center justify-center w-[80px] font-semibold text-white transition-all duration-200 rounded-md bg-gradient-to-r from-fuchsia-600 to-blue-600 hover:opacity-80 focus:opacity-80">

                                    POPULAR
                                </span>
                            </div>
                            <span>
                                220 photos, large 4K (4096x4096) format and 300 dpi, perfect for printing.
                            </span>
                        </div>
                        <CheckoutButton actionUrl={`${BACKEND_URL}/api/stripe/create-checkout-session-2/${user.username}`} price={32} />
                    </div>
                    <div className='flex flex-row w-full items-center justify-between'>
                        <div className="flex flex-col w-72 text-sm text-gray-600">
                            <span className='font-extrabold text-black'>
                                Small
                            </span>
                            <span>
                                120 photos in small (512x512) format, perfect for twitch and social media.
                            </span>
                        </div>
                        <CheckoutButton actionUrl={`${BACKEND_URL}/api/stripe/create-checkout-session-1/${user.username}`} price={19} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddToCart

