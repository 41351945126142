import React from 'react'
import FileInput from '../../components/Upload/FileInput'


const UploadUserPicturesPage = () => {
    return (
        <>
            <div>UploadUserPicturesPage</div>
            <FileInput />
        </>
    )
}

export default UploadUserPicturesPage

