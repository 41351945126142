import React, { useContext } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { AuthContext } from '../../context/auth.context';
import Countdown from 'react-countdown';

const UserAvatarsPage = () => {
    const { user } = useContext(AuthContext);

    const createReplicateModel = async () => {
        const result = await axios.get(`http://localhost:5005/api/create-avatars/${user.username}`)
        console.log("result is :", result)
    }

    const Completionist = () => <span>You are good to go!</span>;

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            // Render a countdown
            return <span>{hours} hours:{minutes} minutes:{seconds} seconds</span>;
        }
    };

    useEffect(() => {
        createReplicateModel()
    }, [])

    return (
        <>
            <div>Congrats! Your photos were successfully uploaded 👏</div>
            <div>We are now generating your avatars !</div>
            <div>Please come back in approximately</div>
            <Countdown
                date={Date.now() + 5000000}
                renderer={renderer}
            />
        </>
    )
}

export default UserAvatarsPage