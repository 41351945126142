import React, { useContext, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/auth.context';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function FileInput() {
  const [files, setFiles] = useState()
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  console.log("files are :", files)

  const handleChange = (e) => {
    setFiles(e.target.files)
  }

  const submit = async event => {
    event.preventDefault()

    const formData = new FormData();
    // append each files to the formData
    if (!files || files.length < 20) {
      console.log("No photos selected!");
      toast.error('You must select at least 20 photos!', {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    }

    for (let i = 0; i < files.length; i++) {
      formData.append("images", files[i])
    }

    try {
      await axios.post(`http://localhost:5005/api/s3/upload/${user.username}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(res => {
          if (res.status === 200) {
            console.log("S3 upload successful!");
            navigate(`/create-avatars/${user.username}`)
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <ToastContainer />
      <form onSubmit={submit}>
        <input onChange={handleChange} multiple type="file" accept="image/*"></input>
        <button type="submit">Submit</button>
      </form>
    </>
  )
}

export default FileInput;