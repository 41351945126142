import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/auth.context";

function HomePage() {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <div className="flex flex-col text-center gap-12 pt-12">
      <div>
        <h1 className="text-white text-5xl">Create your perfect gaming avatar with AI.</h1>
      </div>
      <div className="flex flex-row justify-center gap-24 items-center">
        <div className="w-[40%]">
          Your gaming avatar is the first thing people see when they look at your profile.
          <br />
          We use artificial intelligence to generate an image of you that looks perfect and captures who you are. You can be anything, anywhere, or anyone
        </div>
        <div>
          <Link
            to={isLoggedIn ? "/add" : "/signup"}
            className="text-black p-4 bg-white rounded-lg text-2xl font-bold"
          >
            Create my gaming Avatar
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
