import React, { useState, useEffect } from "react";

function CheckoutButton({ price, actionUrl }) {
    const ProductDisplay = () => (
        <section>
            <form action={actionUrl} method="POST">
                <div className='bg-black w-36 py-2 text-center rounded-md text-white'>
                    <button className="font-bold" type="submit">
                        Select ({price}€)
                    </button>
                </div>
            </form>
        </section>
    );

    const Message = ({ message }) => (
        <section>
            <p>{message}</p>
        </section>
    );

    const [message, setMessage] = useState("");

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
        console.log(message)
    }, []);

    return message ? (
        <Message message={message} />
    ) : (
        <ProductDisplay />
    );
}

export default CheckoutButton